import * as echarts from 'echarts';

const componentChart = () => {

    const observeOptions = {
        root: null,
        threshold: 0.75,
    };


    const ressourcenverbrauch = document.getElementById('ressourcenverbrauch')
    let echartRessourcenverbrauch;
    if (ressourcenverbrauch) {
        echartRessourcenverbrauch = echarts.init(ressourcenverbrauch);
    
        const observeEchartRessourcenverbrauch = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    echartRessourcenverbrauch.setOption({
                        grid: {
                            left: '50%',  
                            right: '2%',  
                        },
                        xAxis: {
                            type: 'value',
                            min: 0,
                            max: 100,
                            interval: 25,
                        },
                        yAxis: {
                            type: 'category',
                            data: [
                                'Emissionen aus stationärer Verbrennung', 'Emissionen aus mobiler Verbrennung', 'Prozessemissionen', 'Emssionen aus Verfüchtigungen', 'Emissionen aus zugekauftem und verbrauchtem Strom',
                                'Emissionen aus weiterer zugekaufter Energie\n(Wärme, Kälte, Dampf, Wasser)', 'Kat. 1: Eingekaufte Waren und Dienstleistungen', 'Kat. 3: Brennstoff und energiebezogene Emissionen\n(nicht in Scope 1 und 2 enthalten)', 'Kat. 5: Produzierter Abfall', 'Kat. 6: Geschäftsreisen',
                                'Kat. 7: Pendeln der Arbeitnehmer', 'Kat. 15: Investitionen', 'Sonstige Quellen'
                            ],
                            inverse: true,
                            axisLabel: {
                                fontSize: 12,
                                lineHeight: 14,
                                rich: {
                                    align: 'center'  
                                }
                            }
                        },
                        series: [
                            {
                                type: 'bar',
                                data: [
                                    { value: 40.57, name: 'Emissionen aus stationärer Verbrennung', itemStyle: { color: '#0e5267' } },
                                    { value: 8.26, name: 'Emissionen aus mobiler Verbrennung', itemStyle: { color: '#0e5267' } },
                                    { value: 0.00, name: 'Prozessemissionen', itemStyle: { color: '#0e5267' } },
                                    { value: 0.00, name: 'Emssionen aus Verfüchtigungen', itemStyle: { color: '#0e5267' } },
                                    { value: 0.20, name: 'Emissionen aus zugekauftem und verbrauchtem Strom', itemStyle: { color: '#0e5267' } },
                                    { value: 0.00, name: 'Emissionen aus weiterer zugekaufter Energie (Wärme, Kälte, Dampf, Wasser)', itemStyle: { color: '#0e5267' } },
                                    { value: 12.93, name: 'Kat. 1: Eingekaufte Waren und Dienstleistungen', itemStyle: { color: '#fbb900' } }, 
                                    { value: 21.84, name: 'Kat. 3: Brennstoff und energiebezogene Emissionen (nicht in Scope 1 und 2 enthalten)', itemStyle: { color: '#fbb900' } },
                                    { value: 1.69, name: 'Kat. 5: Produzierter Abfall', itemStyle: { color: '#fbb900' } },
                                    { value: 3.09, name: 'Kat. 6: Geschäftsreisen', itemStyle: { color: '#fbb900' } },
                                    { value: 87.76, name: 'Kat. 7: Pendeln der Arbeitnehmer', itemStyle: { color: '#fbb900' } },
                                    { value: 0.00, name: 'Kat. 15: Investitionen', itemStyle: { color: '#0e5267' } },
                                    { value: 0.00, name: 'Sonstige Quellen', itemStyle: { color: '#0e5267' } }
                                ],
                                barWidth: '70%',
                                label: {
                                    show: true,
                                    position: 'right',
                                    formatter: function (params) {
                                        return params.value.toFixed(2).replace('.', ',') + ' t';
                                    }
                                },
                                emphasis: {
                                    focus: 'none',  // Kein Hervorheben bei Hover
                                }
                            }
                        ],
                        tooltip: {
                            triggerOn: 'none'  // Tooltip wird nicht bei Mouseover ausgelöst
                        },
                        media: [
                            {
                                query: { minWidth: 992 },
                                option: {
                                    grid: {
                                        left: '40%',
                                        right: '2%'
                                    }
                                }
                            },
                        ]
                    });

                            // Setze den Cursor immer auf Standard nach dem Setzen der Optionen
                echartRessourcenverbrauch.getZr().setCursorStyle('default');
                }
    
                return
            });
        }, observeOptions);
    
        observeEchartRessourcenverbrauch.observe(ressourcenverbrauch);
    }
    

    const ressourcenverbrauchMobile = document.getElementById('ressourcenverbrauchMobile')
    let echartRessourcenverbrauchMobile;
    if (ressourcenverbrauchMobile) {
        echartRessourcenverbrauchMobile = echarts.init(ressourcenverbrauchMobile);

        const observeEchartRessourcenverbrauchMobile = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    echartRessourcenverbrauchMobile.setOption({
                        grid: {
                            left: '15%',  // Erhöht den Abstand zur linken Seite für mehr Platz auf der Y-Achse
                            right: '5%',  // Passt den Abstand zur rechten Seite an, um die X-Achse zu zentrieren
                        },
                        xAxis: {
                            type: 'value',
                            min: 0,
                            max: 100,
                            interval: 25,

                        },
                        yAxis: {
                            type: 'category',
                            data: [
                                '1*', '2*', '3*', '4*', '5*', '6*','7*','8*','9*','10*','11*','12*','13*'
                            ],
                            inverse: true,
                            axisLabel: {
                                // formatter: function (value) {
                                //     return value.split(' ').join('\n'); 
                                // },
                                fontSize: 12, // Schriftgröße anpassen
                                lineHeight: 14, // Zeilenhöhe für bessere Lesbarkeit bei Zeilenumbrüchen
                                rich: {
                                    align: 'center'  // Optional: Text zentrieren
        
                                }
                            }
                        },
                        series: [
                            {
                                type: 'bar',
                                data: [
                                    { value: 40.57, name: 'Emissionen aus stationärer Verbrennung', itemStyle: { color: '#0e5267' } },
                                    { value: 8.26, name: 'Emissionen aus mobiler Verbrennung', itemStyle: { color: '#0e5267' } },
                                    { value: 0.00, name: 'Prozessemissionen', itemStyle: { color: '#0e5267' } },
                                    { value: 0.00, name: 'Emssionen aus Verfüchtigungen', itemStyle: { color: '#0e5267' } },
                                    { value: 0.20, name: 'Emissionen aus zugekauftem und verbrauchtem Strom', itemStyle: { color: '#0e5267' } },
                                    { value: 0.00, name: 'Emissionen aus weiterer zugekaufter Energie (Wärme, Kälte, Dampf, Wasser)', itemStyle: { color: '#0e5267' } },
                                    { value: 12.93, name: 'Kat. 1: Eingekaufte Waren und Dienstleistungen', itemStyle: { color: '#fbb900' } },  // Gelb für Kat. 1
                                    { value: 21.84, name: 'Kat. 3: Brennstoff und energiebezogene Emissionen (nicht in Scope 1 und 2 enthalten)', itemStyle: { color: '#fbb900' } },
                                    { value: 1.69, name: 'Kat. 5: Produzierter Abfall', itemStyle: { color: '#fbb900' } },
                                    { value: 3.09, name: 'Kat. 6: Geschäftsreisen', itemStyle: { color: '#fbb900' } },
                                    { value: 87.76, name: 'Kat. 7: Pendeln der Arbeitnehmer', itemStyle: { color: '#fbb900' } },
                                    { value: 0.00, name: 'Kat. 15: Investitionen', itemStyle: { color: '#0e5267' } },
                                    { value: 0.00, name: 'Sonstige Quellen', itemStyle: { color: '#0e5267' } }
                                ],
                                barWidth: '70%',
                                label: {
                                    show: true,
                                    position: 'right',
                                    formatter: function (params) {
                                        return params.value.toFixed(2).replace('.', ',') + ' t';
                                    }
                                }
                            }
                        ],
                    });
                }

                return
            });
        }, observeOptions);

        observeEchartRessourcenverbrauchMobile.observe(ressourcenverbrauchMobile);
    }

    

  
   
    window.onresize = function () {
        if (ressourcenverbrauch) {
            echartRessourcenverbrauch.resize();
        }
        if (ressourcenverbrauchMobile) {
            echartRessourcenverbrauchMobile.resize();
        }
    };
};



export default componentChart;

